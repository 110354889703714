<template>
    <LoadingWrapper :isLoading="true">
        <div>Processing your purchase...</div>
    </LoadingWrapper>
</template>

<script>
    // <!-- API -->
    import { defineComponent, onMounted } from 'vue';

    // <!-- COMPONENTS -->
    import LoadingWrapper from '@/components/LoadingWrapper.vue';

    // <!-- COMPOSABLES -->
    import { usePurchaseManager } from '@/features/cart/hooks/usePurchaseManager';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'CartIntegration',
        components: {
            LoadingWrapper,
        },
        setup(props, context) {
            // Get the manager.
            const manager = usePurchaseManager();
            // Once mounted, check if purchase valid.
            onMounted(async () => {
                await manager.deliverSubscriptionAndRedirect();
            });
        },
    });
</script>
