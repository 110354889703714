// <!-- API -->
import { ref } from 'vue';
import { computedEager } from '@vueuse/core';
import { deliverSubscription } from '@/api/v2/cart';

// <!-- COMPOSABLES -->
import { useRouter } from 'vue-router';

// <!-- MANAGER MODULE -->
/**
 * Orchestrates the subscription purchase feature.
 */
class PurchaseManager {
    /**
     * Instantiate this manager.
     */
    constructor() {
        /** @type {Router.Instance} */
        this.router = useRouter();

        /** @type {Vue.Ref<number>} The target organization for which the subscription is being purchased. */
        this.organizationId = ref(null);

        /** @type {Readonly<Vue.Ref<Router.LocationQuery>>} The current query parameters.  */
        this.currentQuery = computedEager(() => {
            const currentRoute = this.router.currentRoute.value;
            const query = currentRoute.query;
            return query;
        });

        /** @type {Readonly<Vue.Ref<number>>} */
        this.currentTarget = computedEager(() => {
            const key = this.currentQuery.value?.['acct_id'];
            const id = typeof key === 'string' ? Number.parseInt(key) : null;
            return id;
        });
    }

    /**
     * Redirect to the specified location.
     * @param {Router.RouteLocationRaw} location
     */
    async redirect(location) {
        await this.router.push(location);
    }

    /**
     * Check if the current target points to an existing organization with a pending subscription.
     * @returns {Promise<boolean>}
     */
    async hasTarget() {
        const id = this.currentTarget.value;
        return typeof id === 'number' && id > 0;
    }

    /**
     * Handle the purchase using the current target.
     */
    async deliverSubscriptionAndRedirect() {
        // If the target organization has a pending subscription, we can complete the purchase.
        const isPurchaseValid = await this.hasTarget();

        // Prepare the sanitized request.
        const request = {
            organization: {
                id: this.currentTarget.value,
            },
        };

        // If the purchase is valid, send the request.
        if (isPurchaseValid) {
            // Send the request to the backend.
            await deliverSubscription(request);
            // Handle the response from the backend.
            await this.redirect({ name: 'Home' });
        } else {
            // Send the user back to the signup page.
            await this.redirect({ name: 'Sign Up' });
        }
    }
}

// <!-- EXPORTS -->
/**
 * Composable for managing organization delivery.
 */
export const usePurchaseManager = () => {
    const manager = new PurchaseManager();
    return manager;
};

// <!-- DEFAULT -->
export default usePurchaseManager;
